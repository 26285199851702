// Validation for reports/statement form
window.validations_reports_statement = function () {
  $(document).on('turbolinks:load', function() {
    // hidde tracking select2
    jQuery.validator.addMethod("star_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) <= parseInt($("#statement-end-date-budget").val());
    }, jQuery.validator.format("El mes que EMPIEZA debe ser MENOR O IGUAL al mes que TERMINA"));
    jQuery.validator.addMethod("end_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) >= parseInt($("#statement-start-date-budget").val());
    }, jQuery.validator.format("El mes que TERMINA debe ser MAYOR O IGUAL al mes que EMPIEZA"));

    $("#statement-reports-form").validate({
      rules: {
        start_date : {
          star_date_validation_budget: true
        },
        end_date : {
          end_date_validation_budget: true
        },
      }
    });
  });
  $(document).ready(function() {
    hide_select2_id('statement-cost-center-id-tracking');
    hide_select2_id('statement-currencies-tracking');
  });
}

// Validation for reports/detailed form
window.validations_reports_detailed = function () {
  $(document).on('turbolinks:load', function() {
    // hidde tracking select2
    jQuery.validator.addMethod("star_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) <= parseInt($("#detailed-end-date-budget").val());
    }, jQuery.validator.format("El mes que EMPIEZA debe ser MENOR O IGUAL al mes que TERMINA"));
    jQuery.validator.addMethod("end_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) >= parseInt($("#detailed-start-date-budget").val());
    }, jQuery.validator.format("El mes que TERMINA debe ser MAYOR O IGUAL al mes que EMPIEZA"));

    $("#detailed-reports-form").validate({
      rules: {
        start_date : {
          star_date_validation_budget: true
        },
        end_date : {
          end_date_validation_budget: true
        },
      }
    });
  });
}

// Validation for reports/consolidated form
window.validations_reports_consolidated = function () {
  $(document).on('turbolinks:load', function() {
    // hidde tracking select2
    jQuery.validator.addMethod("star_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) <= parseInt($("#detailed-end-date-consolidated").val());
    }, jQuery.validator.format("El mes que EMPIEZA debe ser MENOR O IGUAL al mes que TERMINA"));
    jQuery.validator.addMethod("end_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) >= parseInt($("#detailed-start-date-consolidated").val());
    }, jQuery.validator.format("El mes que TERMINA debe ser MAYOR O IGUAL al mes que EMPIEZA"));

    $("#detailed-reports-form").validate({
      rules: {
        start_date : {
          star_date_validation_budget: true
        },
        end_date : {
          end_date_validation_budget: true
        },
      }
    });
  });
}

// Validation for reports/consolidated_by_months form
window.validations_reports_consolidated_by_months = function () {
  $(document).on('turbolinks:load', function() {
    // hidde tracking select2
    jQuery.validator.addMethod("star_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) <= parseInt($("#statement-end-date-consolidated_by_months").val());
    }, jQuery.validator.format("El mes que EMPIEZA debe ser MENOR O IGUAL al mes que TERMINA"));
    jQuery.validator.addMethod("end_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) >= parseInt($("#statement-start-date-consolidated_by_months").val());
    }, jQuery.validator.format("El mes que TERMINA debe ser MAYOR O IGUAL al mes que EMPIEZA"));

    $("#consolidated-by-months-reports-form").validate({
      rules: {
        start_date : {
          star_date_validation_budget: true
        },
        end_date : {
          end_date_validation_budget: true
        },
      }
    });
  });
}

// Validation for reports/tracking_by_months_consolidated/_cumulative_tracking_form.html.erb
window.validations_reports_tracking_by_months_consolidated = function () {
  $(document).on('turbolinks:load', function() {
    // hidde tracking select2
    jQuery.validator.addMethod("star_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) <= parseInt($("#tracking-by-months-consolidated-end-date").val());
    }, jQuery.validator.format("El mes que EMPIEZA debe ser MENOR O IGUAL al mes que TERMINA"));
    jQuery.validator.addMethod("end_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) >= parseInt($("#tracking-by-months-consolidated-start-date").val());
    }, jQuery.validator.format("El mes que TERMINA debe ser MAYOR O IGUAL al mes que EMPIEZA"));

    $("#tracking-by-months-consolidated-reports-form").validate({
      rules: {
        start_date : {
          star_date_validation_budget: true
        },
        end_date : {
          end_date_validation_budget: true
        },
      }
    });
  });
}

// Validation for reports/employees/_employees_form.html.erb
window.validations_reports_employees = function () {
  $(document).on('turbolinks:load', function() {
    jQuery.validator.addMethod("star_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) <= parseInt($("#employees-end-date").val());
    }, jQuery.validator.format("El mes que EMPIEZA debe ser MENOR O IGUAL al mes que TERMINA"));
    jQuery.validator.addMethod("end_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) >= parseInt($("#employees-start-date").val());
    }, jQuery.validator.format("El mes que TERMINA debe ser MAYOR O IGUAL al mes que EMPIEZA"));

    $("#employees-reports-form").validate({
      rules: {
        start_date : {
          star_date_validation_budget: true
        },
        end_date : {
          end_date_validation_budget: true
        },
      }
    });
  });
}

// Validation for reports/tracking_by_months_consolidated/_cumulative_tracking_form.html.erb
window.validations_reports_tracking_offices = function () {
  $(document).on('turbolinks:load', function() {
    // hidde tracking select2
    jQuery.validator.addMethod("star_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) <= parseInt($("#tracking-offices-end-date-budget").val());
    }, jQuery.validator.format("El mes que EMPIEZA debe ser MENOR O IGUAL al mes que TERMINA"));
    jQuery.validator.addMethod("end_date_validation_budget", function (value, element) {
      return this.optional(element) || parseInt(value) >= parseInt($("#tracking-offices-start-date-budget").val());
    }, jQuery.validator.format("El mes que TERMINA debe ser MAYOR O IGUAL al mes que EMPIEZA"));

    $("#tracking-offices-form").validate({
      rules: {
        start_date : {
          star_date_validation_budget: true
        },
        end_date : {
          end_date_validation_budget: true
        },
      }
    });
  });
}

// On change id statement-kind-budget
window.kind_report_change = function () {
  switch ($('#statement-kind-report').val()) {
    case '0': // presupuesto
      $('#statement-cost-center-id-budget').prop('disabled', false);
      $('#statement-cost-center-id-tracking').prop('disabled', true);
      $('#statement-currencies-budget').prop('disabled', false);
      $('#statement-currencies-tracking').prop('disabled', true);
      hide_select2_id('statement-cost-center-id-budget');
      hide_select2_id('statement-cost-center-id-tracking');
      hide_select2_id('statement-currencies-budget');
      hide_select2_id('statement-currencies-tracking');
      show_select2_id('statement-cost-center-id-budget');
      show_select2_id('statement-currencies-budget');
      break;
    case '1': // seguimiento
      $('#statement-cost-center-id-budget').prop('disabled', true);
      $('#statement-cost-center-id-tracking').prop('disabled', false);
      $('#statement-currencies-budget').prop('disabled', true);
      $('#statement-currencies-tracking').prop('disabled', false);
      hide_select2_id('statement-cost-center-id-budget');
      hide_select2_id('statement-cost-center-id-tracking');
      hide_select2_id('statement-currencies-budget');
      hide_select2_id('statement-currencies-tracking');
      show_select2_id('statement-cost-center-id-tracking');
      show_select2_id('statement-currencies-tracking');
      break;
    default:
      console.log('Tipo de presupuesto no conocido')
  }
}

// On change id statement-year-to-compare-budget
window.year_change = function () {
  if ($('#statement-year-to-compare-budget').val().length > 0) {
    $('#statement-kind-to-compare-budget').prop('disabled', false);
  } else {
    $('#statement-kind-to-compare-budget').prop('disabled', true);
  }
}

// Display report for fixed_asset general
window.show_report_for_fixed_asset = function (kind_report) {
  $('#body-ledger-account-groups').html('');
  $('#collapseReportStatement').collapse('hide');
  $('#collapseReportDetailed').collapse('hide');
  $('#collapseReportConsolidated_by_offices').collapse('hide');
  $('#collapseReportConsolidated_by_months').collapse('hide');
  $('#collapseReportTrackingByMonthsConsolidated').collapse('hide');
  $('#collapseReportCumulativeTracking').collapse('hide');
  $('#collapseReportEmployees').collapse('hide');
  $('#collapseReport' + kind_report).collapse('show');
}

// Form copy_incomes/structural_cost. Check required and control spinner.
window.check_required_copy_incomes_and_structural_cost = function () {
  if( $('#periods').val().length > 0 ) {
    wait_spinner('wait-spiner-tracking-offices', '2')
  }
}

