import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap";
import '../stylesheets/application'; // This file will contain your custom CSS
import "@fortawesome/fontawesome-free/js/all";
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import 'toastr/toastr.scss';

Rails.start();
Turbolinks.start();
ActiveStorage.start();
window.jQuery = $;
window.$ = $;
global.Rails = Rails;
global.toastr = require('toastr');
global.numeral = require('numeral');

require('admin-lte');
require('datatables.net-bs4');
require('select2/dist/js/i18n/es');
require('maximize-select2-height');
require('numeral');
require('numeral/locales/es-es');
require('@nathanvda/cocoon');
require('jquery-validation/dist/jquery.validate')
require('jquery-validation/dist/localization/messages_es.min')
require('jquery-validation/dist/additional-methods')
require('./custom/datatable_config');
require('./custom/others');
require('./custom/toast');
require('./custom/budget_line');
require('./custom/select2-searchInputPlaceholder');
require('./custom/datatable_ajax');
require('./custom/form_validations');

import Stepper from 'bs-stepper';

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-tooltip="true"]').tooltip();
  // $('.select2').select2({language: 'es', searchInputPlaceholder: '🔎 Buscar...'}).maximizeSelect2Height();
  
  // TODO: Unify this select2 code
  function formatText (icon) {
    if (icon) {
      return $('<span><i class="' + $(icon.element).data('icon') + '"></i> ' + icon.text + '</span>');
    }
  };
  $('.select2').select2({
    language: 'es',
    searchInputPlaceholder: '🔎 Buscar...',
    templateSelection: formatText,
    templateResult: formatText
  }).maximizeSelect2Height();
  $('.select2-no-search').select2({
    language: 'es',
    minimumResultsForSearch: -1,
    templateSelection: formatText,
    templateResult: formatText
  }).maximizeSelect2Height();

  
  if ($(".bs-stepper").length > 0) {
    // only one bs-stepper per page
    window.stepper = new Stepper($(".bs-stepper")[0]);
  }
  numeral.locale('es-es');
  numeral.defaultFormat('0,0.00'); // for currency (cost) view in js. E.g: budget_line.js
});
