// Toggle full screen web browser plugin
window.ToggleFullScreen = function () {
  if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) { //in fullscreen, so exit it
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  } else { //not fullscreen, so enter it
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    }
  }
}

// Animated scrolling to an id
window.ScrollToId = function (id) {
  let go_to = $('#' + id);
  $('html, body').animate({scrollTop: go_to.offset().top - 200}, 500);
  AnimateId(id);
}

// Animated id
window.AnimateId = function (id) {
  let go_to = $('#' + id);
  go_to.css("opacity",0);
  setTimeout( function() {
    go_to.animate({opacity: 1},1000);
  },500);
}

// Scrolling to an id
window.OnlyScrollToId = function (id) {
  let go_to = $('#' + id);
  $('html, body').animate({ scrollTop: go_to.offset().top - 200 }, 500);
}

// Toggle by Id
window.ToggleById = function (id) {
  $('#' + id).toggle(150);
}

// Call Ajax get by url
window.get_ajax_url = function (url) {
  $.ajax({
    url: url,
    data: {authenticity_token: $('[name="csrf-token"]')[0].content},
    method: 'GET',
    success: function (res) {
    }
  });
}

// Animate wait spinner
window.wait_spinner = function (id, size = '1') {
  $('#' + id).html("<i class=\"fas fa-spinner fa-" + size + "x fa-pulse\"></i>");
}
// Animate full page spinner
window.full_page_spinner = function (message = '', id = 'full_page_spinner', size = '4') {
  $('#' + id).parent().children('#spinner_message').html( message );
  if( message == '') {
    $('#' + id).parent().addClass('d-none');
  } else {
    $('#' + id).parent().removeClass('d-none');
  }
  $('#' + id).parent().parent().show();
}
// Animate full page spinner off
window.full_page_spinner_off = function (id = 'full_page_spinner', size = '4') {
  $('#' + id).parent().parent().hide();
}

// Fix dropdown reports in home page. Id hidden the other report
window.home_report_init = function (id) {
  $('#' + id).removeClass('show');
  $('#body-ledger-account-groups').html('');
}

// Autologin with Gesproei
window.autologin = function () {
  wait_spinner('login-spinner-to-gesproei', '2');
  $('#login_gesproei').hide();
  $('#login_gesproei')[0].click();
}

// Hide select2 id with bootstrap class
window.hide_select2_id = function (id) {
  $('#' + id).select2().next().hide();
}

// Show select2 hidden id with bootstrap class
window.show_select2_id = function (id) {
  $('#' + id).select2().next().show();
}

// Click to point to a row in the table
window.click_point_row_table = function (id) {
  $(`#${id} tbody tr`).click(function() {
    $(this).addClass('table-primary').siblings().removeClass('table-primary');
  });
}

// Toast on change budget
window.check_toast_on_change_budget = function (message) {
  if ($('#ledger_account_group_id' ).select2('data')[0].text == 'Gastos de Personal') {
    showOkToast(message);
  }
}
